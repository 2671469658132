/*
 * @Author       : JiangChao
 * @Date         : 2024-05-13 09:17:54
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-05-13 09:17:55
 * @Description  : 
 */
/*************************** 不同机型字体大小自适应 ***************************/
const WeixinJSBridge = window.WeixinJSBridge;
(function () {
	var htmlFontSize = function () { 
		var clientWidth = document.documentElement.clientWidth;
		if (clientWidth >= 750) {
			clientWidth = 750;
		}
		document.documentElement.style.fontSize = (16 * clientWidth) / 375 + 'px';
	}
	window.onresize = function () {
		htmlFontSize();
	};
	htmlFontSize();
    const innerHeight = window.innerHeight;
    window.addEventListener('resize', () => {
        const newInnerHeight = window.innerHeight;
        if (innerHeight < newInnerHeight) {
            document.activeElement.blur();
        }
    });
})();

//禁止微信缩放字体
(function () {
	if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
		handleFontSize();
	} else {
		if (document.addEventListener) {
			document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
		} else if (document.attachEvent) {
			document.attachEvent("WeixinJSBridgeReady", handleFontSize);
			document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
		}
	}
	function handleFontSize() {
		// 设置网页字体为默认大小
		window.WeixinJSBridge.invoke('setFontSizeCallback', {
			'fontSize': 0
		});
		// 重写设置网页字体大小的事件
		window.WeixinJSBridge.on('menu:setfont', function () {
			WeixinJSBridge.invoke('setFontSizeCallback', {
				'fontSize': 0
			});
		});
	}
})();
